import React, { useEffect } from 'react';
import SEO from '../components/Seo';

import Top from '../components/news/Top';
import Articles from '../components/news/Articles';

const News = ({ data, contextData, contextSet, langTo }) => {
  useEffect(() => {
    const isEn = !langTo.startsWith('/en');
    contextSet({ langTo: langTo, isEn: isEn });
  }, []);

  return (
    <React.Fragment>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      <Top data={data} />
      <Articles data={data} isEn={contextData.isEn}  />
    </React.Fragment>
  );
};

export default News;
