import React from 'react';
import styled from 'styled-components';
import GraphImg from 'graphcms-image';
import { Link } from 'gatsby';

import H from '../styled/Heading';
import P from '../styled/Paragraph';

const Wrapper = styled.div`
  width: 100%;
`;

const Image = styled.div`
  margin-bottom: 20px;

  @media only screen and (max-width: 810px) {
    margin-bottom: 4px;
  }
`;

const Heading = styled(H)`
  margin-bottom: 10px;

  @media only screen and (max-width: 810px) {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 3px;
  }
`;

const Paragraph = styled(P)`
  max-width: 560px;
`;

const Article = ({ data, locs, i, isEn }) => {

  return (
    <Wrapper
      // as={data.slug ? Link : 'a'}
      as={data.link && 'a'}
      // to={data.slug && (isEn ? `/en/updates/${data.slug}` : `/updates/${data.slug}`)}
      href={data.link && data.link}
      target={data.link && '_blank'}
    >
      {locs &&
        locs[0] &&
        locs[0].items &&
        locs[0].items[i] &&
        locs[0].items[i].thumbnailImage && (
          <Image>
            <GraphImg image={locs[0].items[i].thumbnailImage} />
          </Image>
        )}

      {locs &&
        locs[0] &&
        locs[0].items &&
        locs[0].items[i] &&
        locs[0].items[i].image &&
        locs[0].items[i].image.height === 0 ? (
        <Image>
          {
            locs[0].items[i].image.url && (
              <img src={locs[0].items[i].image.url} />
            )
          }
        </Image>
      ) : (

        <Image>
          <GraphImg image={locs[0].items[i].image} />
        </Image>
      )
      }

      {data.title && <Heading size={3}>{data.title}</Heading>}

      {data.heading && <Heading size={3}>{data.heading}</Heading>}

      {data.intro && <Paragraph>{data.intro}</Paragraph>}
      {data.text && <Paragraph>{data.text}</Paragraph>}
    </Wrapper>
  );
};

export default Article;
