import React from 'react';
import styled from 'styled-components';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useMediaQuery } from 'react-responsive';

import W from '../styled/Wrapper';

import Article from './Article';

import Footer from '../../components/layout/Footer';

const Bottom = styled.div`
  padding-top: 80px;
  padding-bottom: 0;

  @media only screen and (max-width: 810px) {
    padding-top: 50px;
  }
`;

const Articles = ({ data, isEn }) => {
  const isMob = useMediaQuery({
    query: '(max-width: 810px)',
  });
  return (
    <W>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
        <Masonry gutter={isMob ? '30px' : '40px'}>
          {data.items.map((item, i) => {
            return (
              <Article
                key={i}
                data={item}
                i={i}
                locs={data.localizations}
                isEn={isEn}
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
      <Bottom>
        <Footer isEn={isEn} />
      </Bottom>
    </W>
  );
};

export default Articles;
