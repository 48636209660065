import React from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';
import H from '../styled/Heading';
import P from '../styled/Paragraph';

const TextWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;

  @media only screen and (max-width: 810px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 16px;
  }
`;

const Paragraph = styled(P)`
  max-width: 907px;
  padding-bottom: 25px;

  @media only screen and (max-width: 810px) {
    padding-bottom: 14px;
  }
`;

const Top = ({ data }) => {
  const { title, intro } = data;

  return (
    <W>
      <TextWrapper>
        <H size={1} as={'h1'}>
          {title}
        </H>
        <Paragraph>{intro}</Paragraph>
      </TextWrapper>
    </W>
  );
};

export default Top;
